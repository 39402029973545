<template>
  <CCard>
    <CCardHeader>
      <slot name="header"> <i :class="icon"></i> {{ caption }} </slot>
      <button v-if="button_new" type="button" class="btn-sm btn-primary float-right" @click="action_modal"><i class="fas fa-plus"></i>&nbsp;Nuevo(a) {{btn_name}}</button> 
    </CCardHeader>
    <CCardBody>
      <MyDataTable
        :hover="hover"
        :striped="striped"
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="items"
        :fields="fields"
        column-filter
        items-per-page-select
        :items-per-page="10"
        sorter
        pagination
        table-filter
        cleaner
        :actions="actions"
        :buttonEdit="buttonEdit"
        :buttonDelete="buttonDelete"
        :withActions="withActions"
        :myButtons="myButtons"
        @mtd_action_mybutton="mtd_action_mybutton"
        @action_edit="action_edit"
        @action_delete="action_delete"
      >
        <template #status="{ item }">
          <td>
            <CBadge :color="getBadge(item.status)">
              {{ item.status }}
            </CBadge>
          </td>
        </template>
        <template #show_details="{ item, index }">
          <td class="py-2">
            <CButton
              color="primary"
              variant="outline"
              square
              size="sm"
              @click="toggleDetails(item, index)"
            >
              {{ Boolean(item._toggled) ? "Ocultar" : "Mostrar" }}
            </CButton>
          </td>
        </template>
        <template #details="{ item }">
          <CCollapse
            :show="Boolean(item._toggled)"
            :duration="collapseDuration"
          >
            <CCardBody>
              <CMedia :aside-image-props="{ height: 102 }">
                <h4>
                  {{ item.username }}
                </h4>
                <p class="text-muted">User since: {{ item.registered }}</p>
                <CButton size="sm" color="info" class="">
                  User Settings
                </CButton>
                <CButton size="sm" color="danger" class="ml-1">
                  Delete
                </CButton>
              </CMedia>
            </CCardBody>
          </CCollapse>
        </template>
      </MyDataTable>
    </CCardBody>
  </CCard>
</template>

<script>
import MyDataTable from './DataTable.vue'
export default {
  components :{ MyDataTable},
  name: "Table",
  props: {
    items: Array,
    fields: {
      type: Array,
      default() {
        return ["username", "registered", "role", "status"];
      },
    },
    caption: {
      type: String,
      default: "Table",
    },
    icon:String,
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean,
    button_new:Boolean,
    btn_name:String,
    actions:Boolean,
    buttonEdit:Boolean,
    buttonDelete:Boolean,
    withActions:String,
    myButtons:Array
  },
  methods: {
    mtd_action_mybutton: function (row) {
      this.$emit(row.button.action,row.item);
    },

    action_modal: function(){
      this.$emit('show_modal',true,'store');
    },
    action_ver: function(item){
      this.$emit('action_ver',item);
    },
    action_pass: function(item){
      this.$emit('action_pass',item);
    },
    action_edit: function(item){
        this.$emit('action_edit',item);
    },
    action_delete: function(item){
      this.$emit('action_delete',item);
    },
    /** template */
    getBadge (status) {
      switch (status) {
        case 'Activo': return 'success'
        case 'Inactivo': return 'secondary'
        case 'Pendiente': return 'warning'
        case 'Banned': return 'danger'
        case 'Pagado': return 'success'
        case 'Pendiente de pago': return 'danger'
        default: 'primary'
      }
    },
    toggleDetails (item) {
      this.$set(this.items[item.order], '_toggled', !item._toggled)
      this.collapseDuration = 300
      this.$nextTick(() => { this.collapseDuration = 0})
    }
  },
};
</script>
